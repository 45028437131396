import {useEffect, useState} from "react";

interface Game {
    img: string
    href: string
}

interface Config {
    games: Game[]
    terms: string
    privacy: string
}

const App = () => {
    const [config, setConfig] = useState<Config>({
        games: [],
        terms: '',
        privacy: ''
    });

    useEffect(() => {
        fetch('./config.json')
            .then(response => response.json())
            .then(data => setConfig(data));
    }, []);

    const params =  window.location.search

    const openRegister = () => {
        const register = document.querySelector('.register')
        register?.classList.add('active')
    }

    const closeRegister = () => {
        const register = document.querySelector('.register')
        register?.classList.remove('active')
    }

    const openLogin = () => {
        const login = document.querySelector('.login')
        login?.classList.add('active')
    }

    const closeLogin = () => {
        const login = document.querySelector('.login')
        login?.classList.remove('active')
    }

    const secondCloseRegister = (event: any) => {
        if (event.target && event.target.matches('.register')) {
            closeRegister()
        }
    }

    const secondCloseLogin = (event: any) => {
        if (event.target && event.target.matches('.login')) {
            closeLogin()
        }
    }

    const openGame = (index: number) => {
        const video = document.querySelector(`#id_${index}`)
        video?.classList.add('active')
    }

    const closeGame = (event: any, index: number) => {
        if (event.target && event.target.matches('.video')) {
            const video = document.querySelector(`#id_${index}`)
            video?.classList.remove('active')
        }
    }

    return (
        <div className="container">
            <div className="register" onClick={secondCloseRegister}>
                <form>
                    <div className="form__title">Registration</div>
                    <div>
                        <input type="text" placeholder="First Name"/>
                        <input type="text" placeholder="Last Name"/>
                    </div>
                    <div>
                        <input type="email" placeholder="Email"/>
                        <input type="text" placeholder="User"/>
                    </div>
                    <div>
                        <input type="date" placeholder="Birthday"/>
                        <select name="" id="">
                            <option value="" disabled hidden>Sex</option>
                            <option value="Male">Male</option>
                            <option value="Male">Female</option>
                        </select>
                    </div>
                    <input type="tel" placeholder="Phone"/>
                    <input type="password" placeholder="Password"/>
                    <button className="btn" onClick={(event) => {
                        event.preventDefault()
                        closeRegister()
                    }}>Register</button>
                    <div>
                        ✅ I have read and accept the Terms and Conditions, and Privacy Policy and confirm that I am over 18 years of age
                    </div>
                </form>
            </div>
            <div className="login" onClick={secondCloseLogin}>
                <form>
                    <div className="form__title">Login</div>
                    <input type="email" placeholder="Email"/>
                    <input type="password" placeholder="Password"/>
                    <button className="btn" onClick={(event) => {
                        event.preventDefault()
                        closeLogin()
                    }}>Login</button>
                    <div>
                        ✅ Remember me
                    </div>
                </form>
            </div>
            <div className="promo">
                <h1 className="promo__title">
                    Welcome To bonusslotsbay.com
                </h1>
                <div className="promo__text">
                    Immerse yourself in the unparalleled social casino adventure by signing up on our platform. Delight in the company of your beloved family and cherished friends as you indulge in a world of fun and enjoyment!
                </div>
                <div className="btns">
                    <button className="btn" onClick={openLogin}>Login</button>
                    <button className="btn" onClick={openRegister}>Register</button>
                </div>
            </div>
            <div className="games">
                <div className="games__title">Latest Games</div>
                <div className="games__wrapper">
                    {config.games.map((game, index) => (
                        <div key={index}>
                            <div className="games__game" onClick={() => openGame(index)}>
                                <img src={game.img} alt=""/>
                            </div>
                            <div id={`id_${index}`} className="video" onClick={(event) => closeGame(event, index)}>
                                <iframe src={game.href + params}>
                                </iframe>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="more">
                <p className="more__first">The information you have agreed to provide will be used only for the purpose of this promotion. NO PURCHASE IS NECESSARY.</p>
                <p className="more__second">The games on our site are free to play and the site do not offer “real money gambling” or the opportunity to win real money. Winning at a social casino site does not imply future success at a “real money gambling site”.</p>
                <p className="more__third"> Disclaimer:<br/>
                    Our site is for entertainment purposes only.<br/>
                    You cannot earn real money from playing on our site.<br/>
                    No use of real money is involved.</p>
            </div>
            <footer>
                <div>Copyright © 2023 premiumpotion.org. All rights reserved.</div>
                <div className="links">
                    <a href={config.terms}>Terms</a>
                    <a href={config.privacy}>Privacy Policy</a>
                </div>
            </footer>
        </div>
    );
};

export default App;